import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios'; // Import Axios
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import data from './assets/data.json';
export const EnquiryPopup = ({ show, onClose }) => {
    const [name, setName] = useState('');
    const [contact, setContact] = useState('');
    const [classSelected, setClassSelected] = useState('1');
    const [heardFrom, setHeardFrom] = useState('Our Website');
    const [errors, setErrors] = useState({});
    const [comment, setComment] = useState('');
    const [API_PARENT_LINK, setApiParent] = useState(null);
    // Block background scroll on open
    useEffect(() => {
        setApiParent(data.API_PARENT_LINK);
        // setApiParent2(data.API_PARENT_LINK);
      }, []);
    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
            resetForm();
        }
    }, [show]);

    const validateName = (name) => {
        return name.trim() !== '' ? '' : 'Name is required';
    };

    const validateContact = (contact) => {
        const contactRegex = /^[6-9][0-9]{9}$/;
        return contactRegex.test(contact) ? '' : 'Invalid Contact Number';
    };

    const handleNameChange = (e) => {
        const value = e.target.value;
        setName(value);
        setErrors((prev) => ({
            ...prev,
            name: validateName(value),
        }));
    };

    const handleContactChange = (e) => {
        const value = e.target.value;
        setContact(value);
        setErrors((prev) => ({
            ...prev,
            contact: validateContact(value),
        }));
    };

    // Reset form function
    const resetForm = () => {
        setName('');
        setContact('');
        setClassSelected('1');
        setHeardFrom('Our Website');
        setErrors({});
        setComment('');
    };

    // Form Validation
    const validateForm = () => {
        const newErrors = {
            name: validateName(name),
            contact: validateContact(contact),
        };
        setErrors(newErrors);
        return Object.values(newErrors).every((error) => !error);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        const data = {
            student_name: name,
            contact_number: contact,
            class_interested: classSelected,
            additional_comments: comment,
            how_they_came_to_know: heardFrom

        };
        //console.log("data",data);


        try {

            // Axios request to your backend API
            if (!API_PARENT_LINK) {
                throw new Error("API endpoint not available");
               }
            const response = await axios.post(`${API_PARENT_LINK}AdmissionsInquiry/?school_id=1`, data);
            //console.log("response",response);


            if (response.status === 201) {
                setErrors({});
                toast.success('Thanks for your enquiry! We will reach out to you soon.');
                resetForm();
                onClose();
            }
            if (response.status === 200) {
                setErrors({});
                toast.success('Your inquiry request is already with us! We will reach out to you soon.');
                resetForm();
                onClose();
            }
        } catch (error) {
            toast.error('Failed to submit enquiry. Please try again.');
        }
    };

    if (!show) return null;

    return (
        <div
            className="position-fixed  m-2"
            style={{
                width: '300px',
                top: '15%',
                right: '10%',
                zIndex: 1090,
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                backgroundColor: 'white',

            }}
        >
            <div className="modal-content p-2">
                <div className="modal-header p-1">
                    <h6 className="modal-title text-dark">Enquiry Form</h6>
                    <i
                        className="bi bi-x-lg"
                        onClick={onClose}
                        style={{ fontSize: '1.2rem', color: 'black', cursor: 'pointer' }}
                    ></i>
                </div>
                <div className="modal-body p-2">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-1">
                            <label className="form-label small">Name</label>
                            <input
                                type="text"
                                className={`form-control form-control-sm rounded ${errors.name ? 'is-invalid' : ''}`}
                                placeholder="Enter name"
                                value={name}
                                onChange= {handleNameChange}
                                style={{ border: '0.5px solid gray', transition: 'box-shadow 0.2s, border-color 0.2s' }}
                                onFocus={(e) => (e.target.style.borderColor = 'blue')}
                                onBlur={(e) => (e.target.style.borderColor = 'gray')}
                                onMouseEnter={(e) => (e.target.style.boxShadow = '0 0 4px blue')}
                                onMouseLeave={(e) => (e.target.style.boxShadow = 'none')}
                            />
                            {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                        </div>

                        <div className="mb-1">
                            <label className="form-label small">Contact</label>
                            <input
                                type="text"
                                className={`form-control form-control-sm rounded ${errors.contact ? 'is-invalid' : ''}`}
                                placeholder="Enter contact"
                                value={contact}
                                onChange={handleContactChange}
                                maxLength="10"
                                style={{ border: '0.5px solid gray', transition: 'box-shadow 0.2s, border-color 0.2s' }}
                                onFocus={(e) => (e.target.style.borderColor = 'blue')}
                                onBlur={(e) => (e.target.style.borderColor = 'gray')}
                                onMouseEnter={(e) => (e.target.style.boxShadow = '0 0 4px blue')}
                                onMouseLeave={(e) => (e.target.style.boxShadow = 'none')}
                            />
                            {errors.contact && <div className="invalid-feedback">{errors.contact}</div>}
                        </div>

                        <div className="mb-1">
                            <label className="form-label small">Class</label>
                            <select
                                className="form-select form-select-sm rounded"
                                value={classSelected}
                                onChange={(e) => setClassSelected(e.target.value)}
                                style={{
                                    border: '1px solid gray',
                                    transition: 'box-shadow 0.2s, border-color 0.2s',
                                }}
                            >
                                <option value="Nursery">Nursery</option>
                                <option value="LKG">LKG</option>
                                <option value="UKG">UKG</option>
                                {[...Array(10)].map((_, i) => (
                                    <option key={i + 1} value={i + 1}>
                                        {i + 1}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="mb-1">
                            <label className="form-label small">How did you hear about us?</label>
                            <select
                                className="form-select form-select-sm rounded"
                                value={heardFrom}
                                onChange={(e) => setHeardFrom(e.target.value)}
                                style={{
                                    border: '1px solid gray',
                                    transition: 'box-shadow 0.2s, border-color 0.2s',
                                }}
                            >
                                <option value="Our Website">Our Website</option>
                                <option value="Friends and Family">Friends and Family</option>
                                <option value="Staff and Teachers">Staff and Teachers</option>
                                <option value="Banner and Hoarding">Banner and Hoarding</option>
                                <option value="News Channel">News Channel</option>
                                <option value="Facebook">Facebook</option>
                                <option value="YouTube">YouTube</option>
                                <option value="Instagram">Instagram</option>
                                <option value="Others">Others</option>
                            </select>
                        </div>

                        <div className="mb-1">
                            <label className="form-label small">Comments (Optional)</label>
                            <textarea
                                className="form-control form-control-sm rounded"
                                placeholder="Add comments"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                rows="2"
                                style={{ border: '0.5px solid gray', transition: 'box-shadow 0.2s, border-color 0.2s' }}
                                onFocus={(e) => (e.target.style.borderColor = 'blue')}
                                onBlur={(e) => (e.target.style.borderColor = 'gray')}
                                onMouseEnter={(e) => (e.target.style.boxShadow = '0 0 4px blue')}
                                onMouseLeave={(e) => (e.target.style.boxShadow = 'none')}
                            ></textarea>
                        </div>

                        <div className="d-flex justify-content-end mt-2">
                            <button type="submit" className="btn  btn-sm me-2" style={{ backgroundColor: "#191469", color: 'white' }}>
                                Submit
                            </button>
                            <button type="button" className="btn btn-secondary btn-sm" onClick={onClose}>
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    );
};
